import React from "react"
import Layout from '../components/layout'
import Head from '../components/head'
import '../styles/indexstyle.scss'
import { graphql, useStaticQuery } from 'gatsby'


const PressPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulPress {
      edges {
        node {
          name
          image {
            file {
              url
            }
          }
  
        }
      }
    }
  }
  `)
  
  return (
    <Layout>
      <div id="Awwards_and_articles">
        Articles and reviews
      </div>
      <div id="logosPress">  
        <div id="Group_2">
          <svg class="Rectangle_337">
            <rect id="Rectangle_337" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_338">
            <rect id="Rectangle_338" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <img id="logo-startpoint" src={data.allContentfulPress.edges[3].node.image.file.url} alt="logo-startpoint"></img>
            
          <div id="view">
            <a class="pressLinks" href="http://www.startpointprize.eu/2017/artists/gagyi-botond">
              <span>view</span>
            </a>
          </div>
          <div id="Starterpoint_prize_2017">
            <span>Starterpoint prize 2017</span>
          </div>
        </div>
         <div id="Group_3">
          <svg class="Rectangle_342">
            <rect id="Rectangle_342" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_343">
            <rect id="Rectangle_343" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <div id="view_c">
            <a class="pressLinks" href=" https://www.facebook.com/emuksepsi/videos/1847485792001769/UzpfSTEwMDAwMTY0ODM5MTk5OTozMDYwNjExMjk0OTk0MTQ6MTA6MDoxNTU2NjkzOTk5Oi02NTAzNTAyNDIxODk0NDIzOTQ4/">
              <span>view</span>
            </a>
          </div>
          <div id="_Lbashzban_Gagyi_Botond">
            <span> Lábasházban Gagyi Botond</span>
          </div>
          <div id="Group_1">
            <svg class="Rectangle_339">
              <rect id="Rectangle_339" rx="0" ry="0" x="0" y="0" width="146" height="146">
              </rect>
            </svg>
            <svg class="Rectangle_340">
              <rect id="Rectangle_340" rx="0" ry="0" x="0" y="0" width="56.951" height="55.996">
              </rect>
            </svg>
            <svg class="Rectangle_341">
              <rect id="Rectangle_341" rx="55.996360778808594" ry="55.996360778808594" x="0" y="0" width="56.951" height="55.996">
              </rect>
            </svg>
            <svg class="Path_109" viewBox="779.074 -126.566 35.8 48.202">
              <path id="Path_109" d="M 780.0283813476563 -126.5660247802734 L 779.0739135742188 -126.5660247802734 L 779.0739135742188 -78.36380004882813 C 786.7622680664063 -86.06456756591797 801.23046875 -100.6393127441406 814.8740234375 -114.4019012451172 C 805.3106079101563 -122.0145263671875 793.2019653320313 -126.5660247802734 780.0283813476563 -126.5660247802734 Z">
              </path>
            </svg>
            <svg class="Path_110" viewBox="807.766 -60.861 48.013 35.527">
              <path id="Path_110" d="M 807.7659301757813 -25.33380508422852 L 855.7784423828125 -25.33380508422852 L 855.7784423828125 -25.33380508422852 C 855.7784423828125 -38.82162094116211 851.0089111328125 -51.19476318359375 843.0654296875 -60.86100387573242 C 826.313720703125 -43.9591064453125 814.5203247070313 -32.08939361572266 807.7659301757813 -25.33380508422852 Z">
              </path>
            </svg>
            <svg class="Path_111" viewBox="779.074 116.934 45.613 47.376">
              <path id="Path_111" d="M 803.8650512695313 141.6662750244141 C 795.8253173828125 131.4819641113281 787.5448608398438 120.9951400756836 779.0739135742188 116.9339904785156 L 779.0739135742188 164.3103332519531 L 824.6873168945313 164.3103332519531 C 817.3519287109375 158.7435607910156 810.7296752929688 150.3617706298828 803.8650512695313 141.6662750244141 Z">
              </path>
            </svg>
            <svg class="Path_112" viewBox="812.901 89.264 46.413 48.15">
              <path id="Path_112" d="M 837.179443359375 114.7018890380859 C 844.5228881835938 124.0042724609375 852.0750732421875 133.5655212402344 859.3137817382813 137.41357421875 L 859.3137817382813 89.26399230957031 L 812.9010620117188 89.26399230957031 C 821.8629760742188 95.30307006835938 829.6141357421875 105.1194610595703 837.179443359375 114.7018890380859 Z">
              </path>
            </svg>
          </div>
        </div>
        <div id="Group_4">
          <svg class="Rectangle_344">
            <rect id="Rectangle_344" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_345">
            <rect id="Rectangle_345" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <div id="view_dd">
            <a class="pressLinks" href="https://www.helikon.ro/szilankokbol-epitkezve/">
              <span>view</span>
            </a>
          </div>
          <div id="Szilnkokbl_ptkezve">
            <span>Szilánkokból építkezve</span>
          </div>
          <img id="Helikon-logo-250-px" src={data.allContentfulPress.edges[2].node.image.file.url} alt="Helikon-logo-250-px"></img>
            
        </div>
        <div id="Group_5">
          <svg class="Rectangle_346">
            <rect id="Rectangle_346" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_347">
            <rect id="Rectangle_347" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <div id="view_dj">
            <a class="pressLinks" href="http://www.bukarestiradio.ro/2015/12/07/bukarestben-allit-ki-gagyi-botond-festomuvesz/">
              <span>view</span>
            </a>
          </div>
          <div id="Artful_Contemporary">
            <span>Artful Contemporary</span>
          </div>
          <img id="use4625" src={data.allContentfulPress.edges[5].node.image.file.url} alt="use4625"></img>
            
        </div>
        <div id="Group_6">
          <svg class="Rectangle_348">
            <rect id="Rectangle_348" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_349">
            <rect id="Rectangle_349" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <div id="view_dp">
            <a class="pressLinks" href="http://www.bukarestiradio.ro/2015/12/19/metamorfozis-szepseg-fajdalom-kuzdelem-kitartas-gagyi-botond-festomuvesszel-beszegettunk/">
              <span>view</span>
            </a>
          </div>
          <div id="Artful_Contemporary_interview">
            <span>Artful Contemporary interview</span>
          </div>
          <img id="use4625_dr" src={data.allContentfulPress.edges[5].node.image.file.url} alt="use4625_dr"></img>
            
        </div>
        <div id="Group_7">
          <svg class="Rectangle_350">
            <rect id="Rectangle_350" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_351">
            <rect id="Rectangle_351" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <div id="view_dv">
            <a class="pressLinks" href="https://cluju.ro/expozitie-de-pictura-splinter-gagyi-botond-la-galeria-casa-matei-din-cluj-napoca/">
              <span>view</span>
            </a>
          </div>
          <div id="Lansare_Artful_Contemporary">
            <span>Splinter - Expo</span>
          </div>
          <img id="download" src={data.allContentfulPress.edges[1].node.image.file.url} alt="clujuLogo"></img>
            
        </div>
        <div id="Group_8">
          <svg class="Rectangle_352">
            <rect id="Rectangle_352" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_353">
            <rect id="Rectangle_353" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <div id="view_d">
            <a class="pressLinks" href="https://www.sepsiszentgyorgy.info/archivum/2018/3363-gagyi-botond-festomuvesz-kiallitasa-a-labas-hazban.html">
              <span>view</span>
            </a>
          </div>
          <div id="_Lbashzban_Gagyi_Botond_d">
            <span> Lábasházban Gagyi Botond</span>
          </div>
          <img id="sepsiszentgyorgy-info" src={data.allContentfulPress.edges[6].node.image.file.url} alt="sepsiszentgyorgy-info"></img>
            
        </div>
        <div id="Group_9">
          <svg class="Rectangle_354">
            <rect id="Rectangle_354" rx="0" ry="0" x="0" y="0" width="312" height="312">
            </rect>
          </svg>
          <svg class="Rectangle_355">
            <rect id="Rectangle_355" rx="0" ry="0" x="0" y="0" width="312" height="230">
            </rect>
          </svg>
          <div id="view_ea">
            <a class="pressLinks" href="https://www.modernism.ro/2017/05/26/expo-maraton-2017-gagyi-botond-game-zone-rusu-bogdan-alexandru-vegas-galeria-casa-matei-cluj-napoca/">
              <span>view</span>
            </a>
          </div>
          <div id="Expo_Maraton_2017">
            <span>Expo Maraton 2017</span>
          </div>
          <img id="modernism-logo_alb" src={data.allContentfulPress.edges[4].node.image.file.url} alt="modernism-logo_alb"></img>
            
        </div>
    </div>

        <Head title="Press"/>
    </Layout>    
  )
}

export default PressPage